<template>
  <el-dialog
    v-dragDialog
    title="选择改善方案内容"
    :visible="visible"
    width="1000px"
    :close-on-click-modal="false"
    append-to-body
    @close="close"
  >
    <el-form
      :model="dataForm"
      ref="dataForm"
      label-width="250px"
      :inline="true"
    >
      <el-divider content-position="left" v-if="presces.filter((r) => r.Prescription.CheckType == 8).length>0">关节功能评估改善方案</el-divider>
      <el-form-item
        v-for="item in presces.filter((r) => r.Prescription.CheckType == 8)"
        :key="item.Key"
        :label="format.prescTypeFormat(item.Prescription.PrescType)"
      >
        <el-switch
          active-color="#13ce66"
          inactive-color="#ff4949"
          v-model="item.Enabled"
          @change="handleSwtich(item)"
        />
      </el-form-item>
      <el-divider content-position="left" v-if="presces.filter((r) => r.Prescription.CheckType == 32).length>0">呼吸功能评估改善方案</el-divider>
      <el-form-item
        v-for="item in presces.filter((r) => r.Prescription.CheckType == 32)"
        :key="item.Key"
        :label="format.prescTypeFormat(item.Prescription.PrescType)"
      >
        <el-switch
          active-color="#13ce66"
          inactive-color="#ff4949"
          v-model="item.Enabled"
          @change="handleSwtich(item)"
        />
      </el-form-item>
      <el-divider content-position="left" v-if="presces.filter((r) => r.Prescription.CheckType == 64).length>0">心肺耐力评估改善方案</el-divider>
      <el-form-item
        v-for="item in presces.filter((r) => r.Prescription.CheckType == 64)"
        :key="item.Key"
        :label="format.prescTypeFormat(item.Prescription.PrescType)"
      >
        <el-switch
          active-color="#13ce66"
          inactive-color="#ff4949"
          v-model="item.Enabled"
          @change="handleSwtich(item)"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleReport">生成改善方案</el-button>
      <el-button type="info" @click="visible = false">取消</el-button>
    </div>
  </el-dialog>
</template>
  <script>
import {
  switchEnable,
  getPresces,
  generateReport,
} from "@/api/prescription/checkPrescription.js";
export default {
  components: {},
  data() {
    return {
      visible: false,
      checkKey: "",
      presces: [],
    };
  },
  methods: {
    show(checkKey) {
      this.checkKey = checkKey;
      this.visible = true;
      let param = { key: this.checkKey };
      getPresces(param).then((res) => {
        this.presces = res.Items;
      });
    },
    handleReport() {
      let parma = { key: this.checkKey };
      let that = this;
      generateReport(parma).then((res) => {
        let fileurl = that.baseUrl + res.data;
        this.$emit("update", res.data);
        window.open(fileurl, "_blank");
      });
    },
    handleSwtich(row) {
      let param = { key: row.Key, switchValue: row.Enabled };
      switchEnable(param)
        .then((res) => {
          if (res) {
            this.msg.successMsg("操作成功！");
          }
        })
        .catch((ex) => {
          this.msg.erroMsg("操作失败！");
        });
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
  <style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 10px 20px 20px 20px;
}
</style>