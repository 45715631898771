import request from '@/utils/request'


export function switchEnable(data) {
  return request({
    url: 'checkPrescription/switchEnable',
    method: 'post',
    data
  })
}

export function getPresces(data) {
  return request({
    url: 'checkPrescription/GetPresces',
    method: 'post',
    data
  })
}

export function generateReport(data) {
  return request({
    url: 'checkPrescription/generateReport',
    method: 'post',
    data
  })
}
